@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.AskForProduct {
    @include from-tablet {
        display: block;
    }

    &-Wrapper {
        @include from-tablet {
            display: flex;
        }
    }

    &-ProductName {
        font-size: 16px;
        color: $black;

        @include from-tablet {
            margin-bottom: 34px;
        }
    }

    .Field_type_checkbox {
        .Field-CheckboxLabel {
            color: $grey3;
        }
    }

    &-ImageContainer {
        .Image {
            border: 1px solid $grey4;
        }
    }

    .FieldForm-Fields {
        .Field:first-of-type {
            @include from-tablet {
                margin-top: 0;
            }
        }
    }
    .Button {
        font-size: 13px;
        min-width: 216px;
    }
}
