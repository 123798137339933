@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --product-label-background-sale: #{$red70};
    --product-label-background-default: #{$blue50};
    --product-label-radius: 0;
}

.ProductLabel {
    font-size: 13px;
    line-height: 16px;
    padding: 4px 10px;
    border-radius: 0;
}
