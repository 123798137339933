@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.ProductList {
    scroll-margin: 250px;

    @include mobile {
        scroll-margin: 150px;
    }

    &-ProductsMissing {
        padding: 48px;
        text-align: center;
        background-color: $grey10;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mobile {
            padding: 25px 15px;
        }

        h2 {
            font-size: 30px;
            line-height: 1.4;
            font-weight: 700;
            letter-spacing: 0.03em;
            color: $default-secondary-base-color;
            margin-top: 0;
            margin-bottom: 18px;
        }

        h3 {
            font-family: $font-secondary;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.4;
            color: $default-primary-base-color;
            text-transform: none;
            margin-top: 0;
            top: 0;
            margin-bottom: 16px;
            span {
                font-weight: 600;
            }
        }

        p {
            color: $grey3;
            max-width: 330px;
            font-size: 15px;
            line-height: 1.4;
            text-align: center;
            margin: 0;
        }
    }
}
