@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

[dir='ltr'] .ProductCard {
    --product-cart-border-radius: 0;
    --qty-button-background: #{$white};
    --qty-button-background: 0;
    --image-size: 100px;
    display: flex;
    flex-direction: column;
    &:hover {
        box-shadow: none;
    }
    .ProductLabel {
        &:nth-child(2) {
            top: 20px;
        }

        &:nth-child(3) {
            top: 52px;
        }

        &:nth-child(4) {
            top: 62px;
        }
    }
    &-PriceWrapper,
    &-Name,
    &-Actions,
    &-Sku {
        padding: 0 10px;

        @include mobileAndTablet {
            padding: 0;
        }
        @include desktop {
            padding: 0;
        }
        @include wide-desktop {
            padding: 0;
        }
    }
    &-Link,
    &-Actions {
        padding: 0 20px;

        @include mobile {
            padding: 0 8px;
        }
    }
    &-Actions {
        margin-top: 0;
    }
    &-ProductActions {
        left: 15px;
    }

    &-StatusWrapper {
        display: flex;
        align-items: center;
        padding: 10px 0 2px;
        color: $grey50;
        font-weight: 400;
        font-size: 12px;

        > span {
            margin-left: 6px;
        }
    }

    &-StatusDot {
        display: inline-block;
        background: $green30;
        width: 40px;
        height: 10px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
        }

        &_status {
            position: relative;

            &_Outofstock {
                background: $red20;

                +span {
                    color: $red30;
                }
            }

            &_Lowstocklevel {
                background: $red20;

                &::before {
                    width: 10px;
                    background: $red70;
                }
            }

            &_Mediumstocklevel {
                background: $orange30;

                &::before {
                    width: 20px;
                    background: $orange70;
                }
            }

            &_Highstocklevel {
                background: $green30;

                &::before {
                    width: 30px;
                    background: $green70;
                }
            }

            &_Instock {
                background: $green30;

                &::before {
                    width: 40px;
                    background: $green70;
                }
            }
        }
    }

    &-PriceWrapper {
        .ProductPrice.ProductPrice_hasDiscount {
            min-width: 100%;
            > div {
                display: flex;
                align-items: flex-end;
            }
            .ProductPrice-PriceValue,
            .ProductPrice-SubPrice {
                margin-right: 0;
            }
            .ProductPrice-SubPrice {
                color: $red70;
                font-weight: 700;
            }
            .ProductPrice-HighPrice {
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                margin-left: 9px;
            }
        }
    }
    &-AddToCart {
        display: flex;
        align-items: center;

        .ProductActions-Qty {
            @include qty-input;
            width: 60px !important;
            height: 40px !important;
            margin: 0 4px 0 0 !important;
            input[type='number'] {
                margin: 0;
            }
            button {
                display: none;
            }
        }
    }
    &-Sku {
        font-size: 13px;
        line-height: 16px;
        color: $grey-dark;
        margin-bottom: 8px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            white-space: normal;
            overflow: visible;
            text-overflow: unset;
            margin-bottom: 0;
            height: 24px;
        }
    }
    &-ShoppingList {
        color: $black;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 15px 10px;
        text-align: left;
        width: 100%;
        cursor: pointer;
        @include desktop {
            margin: 15px 0;
        }
        svg {
            margin-right: 11px;
        }
    }
    &-Content {
        @include desktop {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &_layout_list {
        max-width: unset;
        border-top: 1px solid $grey-light;
        padding: 29px 0;

        &:last-of-type {
            border-bottom: 1px solid $grey-light;
        }

        .ProductCard {
            &-Link {
                padding: 0;
                width: 100%;

                .Image {
                    width: var(--image-size);
                    height: var(--image-size);
                    padding-bottom: 0;

                    img {
                        max-width: var(--image-size);
                        max-height: var(--image-size);
                    }
                }

                &:after {
                    display: none;
                }
            }

            &-MainInfo {
                flex: 1 1;
            }

            &-AttributeWrapper {
                margin-right: 10px;
            }

            &-Figure {
                flex-grow: 0;
            }

            &-ProductActions {
                position: relative;
                left: 0;

                .ProductCompareButton-Button {
                    margin-left: -5px;
                }
            }

            &-Content {
                flex-direction: row;
                width: 100%;
                max-width: unset;
                justify-content: space-between;
                align-items: center;
            }

            &-AddToCart {
                .AddToCart {
                    &_layout_list {
                        width: 136px;
                    }
                }
            }

            &-Name {
                margin: 5px 0;
            }
        }

        .ProductLabel {
            top: -10px;
        }

        .ProductActions-Qty {
            &.Field {
                input[type='number'] {
                    border: 1px solid var(--qty-button-border-color);
                }
            }
        }
    }

    &-Labels {
        height: 100%;
        margin-top: -10px;
    }

    &-ShoppingList {
        margin: 15px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        cursor: pointer;

        svg {
            margin-right: 12px;
        }
    }
}
