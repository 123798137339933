@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --option-string-active-color: #{$white};
    --option-string-active-background: #{$default-secondary-base-color};
    --product-attribute-color-border-radius: 5px;
    --product-attribute-color-border-color: #{$default-secondary-base-color};
    --product-attribute-text-border-radius: 5px;
    --option-size: 40px;
}

[dir='ltr'] .ProductAttributeValue {
    &_isNotAvailable {
        overflow: hidden;
        z-index: 1;
        .ProductAttributeValue-Color:before {
            @include not-available;

            height: 80px;
            top: -10px;
            left: 10px;
        }
        .ProductAttributeValue-String:before {
            background: $grey-dark;
            content: '';
            width: 150%;
            height: 1px;
            display: block;
            position: absolute;
            z-index: 0;
            transform: rotate(155deg);
            right: -17px;
            top: 50%;
        }
    }
    &-Image {
        border-radius: var(--product-attribute-color-border-radius);
    }

    &-Color,
    &-Image-Overlay {
        border-radius: var(--product-attribute-color-border-radius);

        &:before {
            height: var(--option-size);
            width: var(--option-size);
            inset-inline-start: 0;
            inset-block-start: 0;
            border-radius: var(--product-attribute-color-border-radius);
            box-shadow: inset 0 0 0 2px var(--product-attribute-color-border-color);
            z-index: 1;
            left: 0;
        }

        &::after {
            display: none;
        }
    }

    &-Text {
        color: $grey3;
        font-size: 15px;
        padding-bottom: 14px;
        label {
            &:first-of-type {
                .input-control {
                    border-color: $grey3;
                    width: 16px;
                    height: 16px;
                    min-width: 16px;

                    @include desktop {
                        margin-block-start: 5px;
                    }
                }
            }
        }

        &_isSelected {
            color: $orange50;
        }
    }

    &-String {
        --option-text-color: #{$grey-dark};
        --option-background-color: #{$grey-light};
        font-size: 16px;
        line-height: 22px;
        font-weight: 600;
        border: none;
        padding: 9px 20px;
        border-radius: var(--product-attribute-text-border-radius);
    }

    &-String {
        &_isSelected {
            --option-text-color: var(--option-string-active-color);
            background: var(--option-string-active-background);
            padding: 9px 12px;
        }
    }

    &:hover {
        .ProductAttributeValue-String {
            --option-text-color: var(--option-string-active-color);
            background: var(--option-string-active-background);
        }
    }
}
