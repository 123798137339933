@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.ProductAlerts {
    &-PriceDrop {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: $orange50;
        display: flex;
        align-items: center;
        cursor: pointer;
        width: auto;
        svg {
            width: 20px;
            height: 20px;
            margin-right: 9px;
        }
    }

    &-InStock {
        color: var(--button-color);
        --button-height: var(--button-height-small);
        --button-hover-height: var(--button-hover-height-small);
        --button-border-radius: 5px;
        --button-hover-border-radius: 5px;
        box-shadow: none;
        text-align: center;
    }
}
