@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ProductDetails {
    &-Heading {
        color: $black;
        letter-spacing: 0;
    }

    &-NavigationItem {
        a {
            font-size: 16px;
            font-weight: 700;
            color: $grey-dark;

            &.active {
                color: $black;
                background: none;
                position: relative;

                &:before {
                    content: '';
                    width: calc(100% - 70px);
                    height: 3px;
                    background: $default-primary-base-color;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    top: 100%;

                    @include mobile {
                        width: calc(100% - 32px);
                    }
                }
            }

            @include desktop {
                &:hover {
                    color: $black;
                    background: none;
                    position: relative;

                    &:before {
                        content: '';
                        width: calc(100% - 70px);
                        height: 3px;
                        background: $default-primary-base-color;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        top: 100%;

                        @include mobile {
                            width: calc(100% - 32px);
                        }
                    }
                }
            }
        }
    }
}
