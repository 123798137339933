@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

[dir='ltr'] .ProductActions {
    &-Title {
        color: $black;
    }

    &-ManufacturerId {
        color: $grey3;
    }

    &-ShoppingList {
        color: $black;
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        width: 100%;
        cursor: pointer;
        margin: 15px 0;

        @include mobileAndTablet {
            margin: -15px 0 15px;
        }

        svg {
            margin-right: 11px;
        }
    }

    &-StatusWrapper {
        display: flex;
        align-items: center;
        padding: 16px 0 2px;
        color: $grey50;
        font-weight: 400;
        font-size: 12px;
        
        > span {
            margin-left: 6px;
        }
    }

    &-StatusDot {
        display: inline-block;
        background: $green30;
        width: 40px;
        height: 10px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 0;
            height: 100%;
        }

        &_status {
            position: relative;

            &_Outofstock {
                background: $red30;

                +span {
                    color: $red30;
                }
            }

            &_Lowstocklevel {
                background: $red30;

                &::before {
                    width: 10px;
                    background: $red70;
                }
            }

            &_Mediumstocklevel {
                background: $orange30;

                &::before {
                    width: 20px;
                    background: $orange70;
                }
            }

            &_Highstocklevel {
                background: $green30;

                &::before {
                    width: 30px;
                    background: $green70;
                }
            }

            &_Instock {
                background: $green30;

                &::before {
                    width: 40px;
                    background: $green70;
                }
            }
        }
    }

    &-AskForProduct {
        a {
            letter-spacing: 0;
            color: $black;
            text-transform: uppercase;
        }

        svg path {
            fill: $black;
        }
    }

    &-Stock,
    &-DeliveryTime,
    &-DeliveryCost {
        color: $black;
        font-weight: bold;
    }

    &-Stock,
    &-DeliveryTime {
        svg path {
            stroke: $black;
        }
    }

    &-DeliveryCostWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &-DeliveryCost {
        button {
            color: $black;
            font-weight: bold;
        }

        svg path {
            fill: $black;
        }
    }

    &-FreeDeliveryMsg {
        padding-left: 0;
        margin-left: 0;

        &:before {
            display: none;
        }
    }

    .ProductWishlistButton,
    .ProductCompareButton {
        button {
            svg path {
                stroke: $default-primary-base-color;
            }
        }
    }

    &-AddToCartWrapper .ProductActions-AddToCart,
    &-AddToCartMobile .ProductActions-AddToCart {
        --button-height: 48px;
        --button-hover-height: 48px;

        &:before {
            content: '';
            position: absolute;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: calc(100% + 2px);
            background: $green40;
        }

        svg {
            position: absolute;
            left: 8px;
            width: 22px;
            height: 22px;
            stroke: none;

            path {
                fill: $white;
            }
        }

        span {
            margin-left: 40px;
            letter-spacing: 0;
        }
    }

    &-AddToCartMobile {
        .ProductActions-AddToCart {
            @include mobile {
                max-width: 204px;
            }
        }
    }

    &-PriceLabelWrapper {
        align-items: center;
        flex-wrap: wrap;

        .ProductLabel {
            margin-bottom: 8px;
        }

        .ProductPrice-PriceValue,
        .ProductPrice-SubPrice {
            font-weight: 400;
            font-size: 23px;
            line-height: 27px;
        }

        .ProductPrice-SubPrice {
            font-size: 24px;
            margin-right: 16px;
        }

        .ProductPrice.ProductPrice_hasDiscount {
            min-width: 100%;

            >div {
                display: flex;
                align-items: flex-end;
            }

            .ProductPrice-HighPrice {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: -0.2px;

                @include from-tablet {
                    font-size: 16px;
                    line-height: 19px;
                }
            }

            .ProductPrice-PriceValue,
            .ProductPrice-SubPrice {
                font-weight: 400;
                font-size: 23px;
                line-height: 27px;

                @include from-tablet {
                    font-weight: 400;
                    font-size: 23px;
                    line-height: 27px;
                }
            }

            .ProductPrice-SubPrice {
                font-size: 24px;
                color: $red70;
                font-weight: 700;

                @include from-tablet {
                    font-size: 24px;
                }
            }
        }
    }

    &-LoginButton {
        font-size: 13px;
        padding: 0;

        @include from-tablet {
            padding: 0 26px;

            &:active,
            &:hover {
                padding: 0 26px;
            }
        }

        &.Button:not([disabled]):hover,
        &.Button:not([disabled]):focus {
            @include desktop {
                padding: 0 26px;
            }
        }
    }

    &-DiscountBlock {
        align-items: center;
        background-color: var(--primary-base-color);
        color: $white;
        display: flex;
        flex-direction: column;
        font-weight: 600;
        justify-content: center;
        margin-right: 16px;
        padding: 16px;

        &Value {
            font-size: 16px;
        }
    }

    &-StandardPriceBlock {
        color: $grey3;
        display: grid;
        font-size: 13px;
        gap: 0 4px;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 10px;
        width: fit-content;
    }
}

.ProductPage .ProductActions-Qty.Field {
    input[type='number'] {
        margin: 0;
        border-left: 0;
        border-right: 0;
        height: 48px;
        width: 65px;
        color: $black;

        @include mobile {
            width: 30px;
        }

        ~button {
            width: 40px;
            border-width: 1px;
            height: 48px;
            border-color: $grey4;
            --qty-button-border-disabled: #{$grey4};

            @include mobile {
                width: 30px;
            }
        }
    }
}
