@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ProductAlerts {
    &-PriceDropContent {
        color: $black;
        position: relative;

        &:before {
            display: block;
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 2px;
            left: 0;
            background: $black;
        }
    }
}
