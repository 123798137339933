@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

[dir='ltr'] .ProductActions {
    &-BrandLink {
        display: block;

        .Image_hasSrc,
        .Image_imageStatus_image_loaded {
            height: auto;
            overflow: unset;
        }
    }
    &-Reviews {
        margin: 0;
        .ProductReviewRating {
            margin: 0 0 0 -5px;
        }

        + .ProductCompareButton {
            @include from-tablet {
                display: none;
            }
        }
    }

    &-Stock,
    &-DeliveryTime,
    &-DeliveryCost {
        display: flex;
        align-items: center;
        color: $green70;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        font-family: $font-secondary;
        margin: 24px 0;

        &_unavailable {
            color: $red50;
        }

        svg {
            width: 24px;
            height: 24px;
            margin-right: 30px;
            pointer-events: none;
        }
    }

    &-DeliveryTime,
    &-DeliveryCost {
        color: $blue50;
    }
    &-Stock {
        border-top: 1px solid $grey5;
        padding-top: 24px;
        cursor: default;

        @include mobile {
            flex-wrap: wrap;
        }
    }
    &-DeliveryCost {
        flex-wrap: wrap;
        svg {
            width: 25px;
        }
        button {
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            font-family: $font-secondary;
            cursor: pointer;
            color: $blue50;
        }
    }
    &-FreeDeliveryMsg,
    &-StockQty {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: $grey3;
        margin-left: 16px;
        padding-left: 16px;

        @include mobile {
            margin-left: 55px;
            margin-top: 10px;
            padding: 0;
            width: 100%;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 4px;
            width: 1px;
            height: 12px;
            background: $grey3;

            @include mobile {
                display: none;
            }
        }
    }
    &-AskForProduct {
        display: flex;
        align-items: center;
        padding: 28px 0;
        border-top: 1px solid $grey5;
        a {
            font-family: $font-primary;
            font-weight: bold;
            font-size: 15px;
            line-height: 140%;
            letter-spacing: 0.07em;
            color: $blue50;
        }
        svg {
            fill: $orange50;
            margin-right: 20px;
            width: 23px;
            height: 16px;
        }
    }
    &-ShortDescription {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 15px;
        line-height: 150%;
        color: $grey3;
        margin: 24px 0;
        max-width: 675px;
        cursor: default;
    }
    &-PriceLabelWrapper {
        display: flex;
        .ProductLabel {
            position: static;
            margin-right: 16px;
        }
    }
    &-PriceWrapper {
        margin: 0;
        .ProductPrice-PriceValue {
            font-size: 28px;
            line-height: 93%;
            @include from-tablet {
                font-size: 32px;
                line-height: 81%;
            }
        }
        .ProductPrice_hasDiscount {
            .ProductPrice-Price .ProductPrice-PriceValue {
                font-size: 28px;
                line-height: 93%;
                color: $black;
                margin-right: 16px;
                @include from-tablet {
                    font-size: 32px;
                    line-height: 81%;
                }
            }
            .ProductPrice-HighPrice {
                font-size: 16px;
                line-height: 112%;
                color: $grey-dark;
                @include from-tablet {
                    font-size: 18px;
                    line-height: 100%;
                }
            }
        }
    }
    &-TitleWrapper {
        display: grid;
        grid-template-columns: auto 90px;
        grid-gap: 20px;
    }
    &-Title {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 22px;
        line-height: 135%;
        letter-spacing: 0.07em;
        color: $default-primary-base-color;

        @include desktop {
            max-width: 493px;
        }
    }
    &-TierPrices + .ProductActions-ActionsWrapper {
        flex-wrap: wrap;
    }
    &-Section {
        &_type_manufacturerId {
            min-width: 100%;
        }
    }
    &-ManufacturerId {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        margin: 15px 0;
        color: $grey-dark;

        @include mobileAndTablet {
            margin: 15px 0 0;
        }
    }
    &-Qty.Field {
        --qty-button-height: 47px;
        @include qty-input;

        input[type='number'] {
            width: 73px;
            margin: 0 2px;

            @include mobile {
                width: 37px;
                margin: 0 1px;
            }

            ~ button {
                @include mobile {
                    --qty-button-width: 32px;
                }
            }
        }
    }
    &-AddToCartWrapper .ProductActions-AddToCart,
    &-AddToCartMobile .ProductActions-AddToCart {
        --button-height: 47px;
        --button-hover-height: 47px;
        margin: 0 17px 0 0;
        max-width: 266px;
        order: 0;
        span {
            letter-spacing: 0.07em;
            font-weight: bold;
            font-size: 15px;
            line-height: 140%;
            font-family: $font-primary;
        }
        svg {
            width: 25px;
            height: 25px;
            margin-right: 16px;
        }
    }
    &-AddToCartWrapper .ProductAlerts-InStock,
    &-AddToCartMobile .ProductAlerts-InStock {
        --button-height: 47px;
        --button-hover-height: 47px;
        margin: 0 17px 0 0;
        max-width: 266px;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        font-family: $font-primary;
        background: $blue30;
        border-color: $blue30;
    }
    &-AddToCartWrapper .ProductActions-Section.ProductActions-Section_type_alerts,
    &-AddToCartMobile .ProductActions-Section.ProductActions-Section_type_alerts {
        margin-right: 10px;
    }
    &-PriceBlock {
        margin-top: 24px;
        @include from-tablet {
            display: flex;
            align-items: center;
        }
        .ProductActions-Section_type_alerts {
            margin: 5px 0;
            @include from-tablet {
                margin: 0 0 0 20px;
                top: 0;
            }
        }
    }
    &-Schema {
        min-width: unset;
    }
    &-AddToCartWrapper .ProductCompareButton,
    &-AddToCartMobile .ProductCompareButton {
        @include mobile {
            display: none;
        }
    }

    &-AddToCartWrapper,
    &-AddToCartMobile {
        justify-content: flex-start;
        .ProductActions-Section_type_alerts {
            top: 0;
        }
    }
    &-AddToCartMobile {
        margin-top: 30px;

        @include mobileAndTablet {
            display: flex;
            align-items: center;
            margin-bottom: 32px;
        }

        &.fixed {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            background: $white;
            z-index: 4;
            margin: 0;
            padding: 8px 16px;

            .ProductActions-Qty {
                display: none;

                @include from-tablet {
                    display: flex;
                }
            }

            .AddToCart,
            .ProductAlerts-InStock.Button {
                max-width: unset;
            }
            .ProductActions-Section.ProductActions-Section_type_alerts {
                &:not(:empty) {
                    width: 100%;
                    margin-right: 10px;
                }
            }
        }

        .ProductActions-AddToCart {
            @include mobile {
                min-width: unset;
                max-width: 160px;
                margin: 0 10px 0 0;
            }
            span {
                @include mobile {
                    font-size: 13px;
                    line-height: 140%;
                }
            }
        }
        .ProductAlerts-InStock {
            @include mobile {
                min-width: unset;
                margin: 0 10px 0 0;
                font-size: 13px;
                line-height: 140%;
            }
        }
    }
    .ProductWishlistButton,
    .ProductCompareButton {
        width: 40px;
        height: 40px;
        margin: 0 13px 0 0;

        @include mobile {
            margin: 0;
        }

        button {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            box-shadow: none;
            svg path {
                stroke: $blue50;
            }

            &:hover {
                background: $blue50;
                svg path {
                    stroke: $white;
                }
            }
        }

        &-Button_isInWishlist.Button {
            svg path {
                stroke: $white;
            }
        }
    }
}
