@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.ProductsSlider {
    max-width: var(--content-wrapper-width);
    margin: 0 auto;
    @include desktop {
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);
    }

    &-Placeholder {
        @include placeholder(500px);
    }

    &-Slide {
        min-height: 500px;
    }

    .slick-slide > div {
        margin: 0 10px;
        padding: 30px 20px;
        @include tablet {
            padding: 40px;
        }
        @include widest-desktop {
            padding: 30px 0;
            margin: 0 auto;
            max-width: 250px;
        }
    }
    .slick-slide {
        @include widest-desktop {
            margin-left: -3px;
        }
    }

    .slick-list {
        margin: 0 -10px;
        @include widest-desktop {
            margin: 0 auto;
            padding: 0 20px;
        }
    }
    .slick-prev {
        left: 0;
        @include desktop {
            left: 0;
        }
        @include widest-desktop {
            left: -50px;
        }
    }
    .slick-next {
        right: 0;
        @include desktop {
            right: 0;
        }
        @include widest-desktop {
            right: -50px;
        }
    }
    .AdvoxSlider-Heading {
        font-size: 30px;
        margin: 10px;
    }
    .slick-list {
        @include mobile {
            padding: 0 20% 0 0 !important;
        }
    }
}
