@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.ProductsRelatedByAttribute {
    margin: 24px 0;
    border-top: 1px solid $grey5;
    padding-top: 24px;

    @include mobileAndTablet {
        margin: 14px 0 24px;
    }

    &-Label {
        font-family: $font-secondary;
        font-weight: normal;
        font-size: 13px;
        line-height: 150%;
        color: $blue50;
        margin-bottom: 18px;
    }

    &-List {
        display: flex;
        overflow-x: auto;
        gap: 23px;
        // Firefox support
        scrollbar-width: none !important;
        scrollbar-color: none !important;

        &::-webkit-scrollbar {
            display: none;
            -ms-overflow-style: none;
        }

        &::-webkit-scrollbar-thumb {
            display: none;
        }

        @include mobile {
            width: calc(100% + 40px);
            gap: 18px;
            margin: 0 -20px;
        }

        @include from-tablet {
            max-width: calc((6 * 80px) + (5 * 23px));
        }

        @include desktop {
            flex-wrap: wrap;
            justify-content: flex-start;
        }

        a {
            display: block;
            width: 16px;
            min-width: 61px;
            height: 58px;
            border-radius: 5px;
            border: 1px solid $grey6;
            opacity: 0.4;
            transition: border-color, opacity 0.25s ease-in-out;
            padding: 8px;

            @include from-tablet {
                width: 80px;
                min-width: 80px;
                height: 76px;
            }

            &:hover {
                border-color: $orange50;
                opacity: 1;
            }

            &:first-child {
                @include mobile {
                    margin-left: 20px;
                }
            }

            &:last-child {
                @include mobile {
                    margin-right: 20px;
                }
            }

            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}