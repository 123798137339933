@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --product-compare-heading-color: #{$default-primary-base-color};
    --product-compare-scrollbar-color: #{$default-secondary-base-color};
}

[dir='ltr'] .ProductCompare {
    margin-bottom: 130px;
    padding-bottom: 20px;

    @include mobileAndTablet {
        padding-left: var(--product-compare-item-gap);
        padding-right: var(--product-compare-item-gap);
    }

    @include mobile {
        --product-compare-column-width: calc(100vw - var(--product-compare-item-gap) * 2);
        --product-compare-sidebar-width: calc(100vw - var(--product-compare-item-gap) * 2);
    }

    &-Heading {
        color: var(--product-compare-heading-color);
        font-size: 22px;
        font-weight: 700;
        letter-spacing: 0.07em;
        margin-bottom: 40px;

        @include mobileAndTablet {
            margin-bottom: 33px;
            margin-left: 17px;
        }
    }

    &-Item {
        @include mobile {
            padding-right: 0;
        }
    }

    &-Row {
        margin-top: 0;
    }

    &-FirstColumn {
        .Button {
            max-width: 175px;
            max-height: 40px;
            --hollow-button-border-radius: 5px;

            &:hover,
            &:focus {
                border-radius: var(--hollow-button-border-radius);
            }
        }
    }

    &-Empty {
        font-size: 15px;
        margin-left: 0;
    }

    .ClearButton {
        align-items: flex-end;
        padding-bottom: 24px;

        @include mobileAndTablet {
            display: none;
        }
    }

    &::-webkit-scrollbar {
        height: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: $grey5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--product-compare-scrollbar-color);
    }
}
